import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Facturas ,NotasCredito,Pagos,Saldos} from "../pages/cfdi";
import { ChangePassword } from "../pages/auth/ChangePassword";
import {LogIn} from "../pages/auth/LogIn";
import AuthLayout from "../pages/layouts/AuthLayout";
import MainLayout from "../pages/layouts/MainLayout";
import { CustomerInfo } from "../pages/user/CustomerInfo";
import { ResetPassword } from "../pages/auth/ResetPassword";
import { Carrito } from "../pages/pedidos/Carrito";
import { Catalogo } from "../pages/products";
import { MisPedidos } from "../pages/user/MisPedidos";
import { Comentarios } from "../pages/user/Comentarios";




export const AppRouter = () => {
  return (
    <BrowserRouter basename="">
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<CustomerInfo/>} />
          <Route path="facturas" element={<Facturas/>} />
          <Route path="notas-credito" element={<NotasCredito/>} />
          <Route path="saldos" element={<Saldos/>} />
          <Route path="pagos" element={<Pagos/>} />
          <Route path="mi-informacion" element={<CustomerInfo/>} />
          <Route path="productos-catalogo" element={<Catalogo/>}/>
          <Route path="carrito"  element={<Carrito/>}/> 
          <Route path="mis-pedidos" element={<MisPedidos/>}/>
          <Route path="comentarios-sugerencias" element={<Comentarios/>}/>
        </Route>
        <Route path="/auth"  element={<AuthLayout />}>
          <Route path="/auth"  element={<LogIn />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="reset-password" element={<ResetPassword/>}/>
        </Route>
        <Route path="/*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};
