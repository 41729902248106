
import { httpClient } from "../config/axiosconfig";
import { Pedido, UltimosPedidos ,ReenviarCorreoPedido} from "../interfaces";

export const postOrdenVenta = async (
  token: string,
  data: Pedido
): Promise<boolean> => {
  console.log({ data, token });
  const response = await httpClient.post(
    "Order/crear-orden",
    data,
    { headers: { Authorization: token } }
  );

  if (response.status === 200) {
    return true;
  } else {
    throw response.data;
  }
};

export const getRemoteMisPedidos = async (token: string): Promise<UltimosPedidos[]> => {
  
  const response = await httpClient.get<UltimosPedidos[]>("Order/get-ordenes?quantity=30",{ headers: { Authorization: token } });

  if (response.status === 200) {
    return response.data;
  } else {
    throw response.data;
  }
};

export const reenviarPedido = async (token:string,correoPedido:ReenviarCorreoPedido):Promise<boolean> =>{
  const response = await httpClient.post<boolean>("Order/reenviar-orden",correoPedido,{ headers: { Authorization: token } });
  if (response.status === 200) {
    return response.data;
  } else {
    throw response.data;
  }
}
