import { List,ListItem,ListItemIcon,ListItemButton,ListItemText } from '@mui/material'
import { Link } from 'react-router-dom';
import { rutasMain } from '../../router/routes';

interface Props{
    open:boolean;
    handleClose:()=>void;
}

export const ListMenuMain = ({open,handleClose}:Props) => {

  return (
    <List>
          {rutasMain.map(({to,Icon,name,isColored},index) => (
              <ListItem
                key={index}
                disablePadding
                sx={{ display: "block", color: "inherit" }}
                component={Link}
                to={to}
                onClick={handleClose}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                      color:isColored?"#fe5000":"inherit"
                    }}
                  >
                     <Icon /> 
                  </ListItemIcon>
                  <ListItemText
                    primary={name}
                    sx={{ opacity: open ? 1 : 0,color:isColored?"#fe5000":"inherit" }}
                  />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
  )
}
