import { Filtro, Producto, ProductosState } from "../interfaces"

export type ProductsReducerTypes = 
|{type:"[PRODUCTOS] Get",payload:{products:Producto[],pages:number}}
|{type:"[PRODUCTOS] Filter",payload:{products:Producto[],pages:number,filtros:Filtro} }
|{type:"[PRODUCTOS] Error",payload:{message:string} }
|{type:"[PRODUCTOS] Loading"}
|{type:"[PRODUCTOS] ChangePage",payload:{page:number,products:Producto[],pages:number} }

const initialState:ProductosState = {
    products: [], page: 1, pageSize: 20,
    filtros: { busqueda: "", familias:[] },
    pages: 0
};

export const productsReducer = (state:ProductosState = initialState,action:ProductsReducerTypes):ProductosState => {

    switch (action.type) {
        case "[PRODUCTOS] Get":
            return {
                ...state,
                loading:false,
                products:action.payload.products,
                page:1,
                pages:action.payload.pages,
                filtros: {
                    busqueda:"",
                    familias:[]
                }
            }
        case "[PRODUCTOS] Filter":  
            return {
                ...state,
                loading:false,
                products:action.payload.products,  
                page:1,
                pages:action.payload.pages,
                filtros:action.payload.filtros
            }
      
        case "[PRODUCTOS] Error":
            return{
                ...state,
                loading:false,
                error:action.payload.message
            }
        case "[PRODUCTOS] Loading":
            return {
                ...state,
                loading:true
            }
        case "[PRODUCTOS] ChangePage":
            return {
            ...state,
            page:action.payload.page,
            products:action.payload.products,
            pages:action.payload.pages,
            loading:false
        }
        default:
            return state
    }
}