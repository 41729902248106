
import { Action ,ActionCreator,Dispatch} from "redux";
import { ThunkAction } from "redux-thunk";

import { changePasswordRemote, loginRemote } from "../data/authRemote";
import { setLocalStorageAuth } from "../helpers/localStorage";
import { AuthState } from "../interfaces";

import { AuthTypes, AuthReducerTypes } from "../reducers/authreducer";





export const LogInAction: ActionCreator<ThunkAction<Promise<Action<AuthState>>, AuthReducerTypes, void,any>> = (usuario:string,password:string) => {
  return async (dispatch: Dispatch<AuthReducerTypes>): Promise<Action> => {
    try {
        const remoteAuth = await loginRemote(usuario, password);
    
        if (remoteAuth.isLoged) {
           await setLocalStorageAuth({
            token: remoteAuth.token,
            isLoged: true,
            haveToChange: false,
          });
          
        return  dispatch({
              type: AuthTypes.LogIn,
              payload: { token: remoteAuth.token },
            });
       
        } else {
          //manda a contraseña
        return  dispatch({
            type: AuthTypes.ChangePassword,
            payload: { token: remoteAuth.token },
          });
        }
      } catch (error: any) {
      return   dispatch({
          type:AuthTypes.Error,
          payload: { message: error.response.data },
        });
      }
  };
};




export const changePasswordAction: ActionCreator<ThunkAction<Promise<Action<AuthState>>, AuthReducerTypes, void,any>> = ( oldPassword:string,
  newPassword:string,
  tokenP:string) => {
  return async (dispatch: Dispatch<AuthReducerTypes>): Promise<Action> => {
    try {
      const token = await changePasswordRemote(
        oldPassword,
        newPassword,
        tokenP
      );
      await setLocalStorageAuth({
        token: token,
        isLoged: true,
        haveToChange: false,
      });
      
       return dispatch({ type:AuthTypes.LogIn, payload: { token } });
      
    } catch (error: any) {
     return dispatch({
        type: AuthTypes.Error,
        payload: { message: error.response.data },
      });
    }
  };
};


export const logOutAction: ActionCreator<ThunkAction<Promise<Action<AuthState>>, AuthReducerTypes, void,any>> = ( ) => {
  return async (dispatch: Dispatch<AuthReducerTypes>): Promise<Action> => {
    try {

      
       return dispatch({ type:AuthTypes.LogOut, payload: {  } });
      
    } catch (error: any) {
     return dispatch({
        type: AuthTypes.Error,
        payload: { message: error.response.data },
      });
    }
  };
};