import { useState } from "react";
import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { ReenviarCorreoPedido, UltimosPedidos } from "../../interfaces";
import { ReactMultiEmail } from "react-multi-email";
import { useSnackbar } from "notistack";


interface Props {
  onClose: () => void;
  isOpen?: boolean;
  pedido: UltimosPedidos;
  enviarCorreo: (pedido: ReenviarCorreoPedido) => Promise<boolean>;
}
export const ModalCorreo = ({isOpen = true,pedido,onClose,enviarCorreo,}: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [emails, setEmails] = useState<string[]>([]);
  const onSend = async () => {
    try {
        await enviarCorreo({
        idOrden: pedido.id,
        correos: emails});
        enqueueSnackbar("Se envío el correo con éxito", { variant: "success" });
        setEmails([])
    } catch (error) {
      enqueueSnackbar(error as string, { variant: "error" });
    }


    onClose();
  };
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogContent sx={{ width: 600 }}>
        <h3>Ingresa los correos</h3>
        <ReactMultiEmail
          emails={emails}
          onChange={(_emails: string[]) => {
            setEmails(_emails);
          }}
          autoFocus={true}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
        <Button onClick={onSend}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
};
