import { useRef } from "react";
import {ColumnDirective,ColumnsDirective,GridComponent,Page,Inject,Sort, ExcelExport,Toolbar
,AggregatesDirective,AggregateDirective, AggregateColumnsDirective,AggregateColumnDirective,Aggregate} from "@syncfusion/ej2-react-grids";
import { DownloadOutlined } from "@mui/icons-material";
import { Button,Typography} from "@mui/material"; 
import { Saldo } from "../../../interfaces";
import { useDownloadFile } from "../../../hooks";

interface Props {
    saldos:Saldo[]
}

export const GridSaldos = ({saldos}:Props) => {
    const grid = useRef<GridComponent>(null)
    const toolbar:string[] = [ 'ExcelExport']
    const toolbarClick = (args:any) => {
      
      if (grid) {
         
          if(args.item.id ==="Grid_excelexport")
              grid.current?.showSpinner();
              grid.current?.excelExport({});
          
      }
    };    
    const excelExportComplete = () => {
      if (grid) {
          grid.current?.hideSpinner();
      }
    }
  
    
  
    const AggregateTemplateSum =( {sumInfo,title}:any) => {
      return(
        <Typography variant="subtitle2">{sumInfo?.Sum}</Typography>
      )
    } 
  
    const TrustTemplate = (props: Saldo) => {
      const { downloadBase64 } = useDownloadFile();
      return (
        <Button size="small" color="primary" variant="text" onClick={() => downloadBase64(props.documento.toString(), props.tipo,props.serie)} startIcon={<DownloadOutlined />}>
          Descargar
        </Button>
      );
    };
   
   return (
    
        <GridComponent dataSource={saldos} allowSorting allowPaging allowExcelExport 
                       toolbar={toolbar} pageSettings={{ pageSize: 10,pageSizes:true  }} 
                       excelExportComplete={excelExportComplete}
                       toolbarClick={toolbarClick}  ref={grid} >
          <ColumnsDirective>
            <ColumnDirective width={150} field="documento" headerText="Número Documento"/>
            <ColumnDirective width={150} field="vencimiento" type="datetime" headerText="Fecha de vencimiento" format="dd/MM/yyyy hh:mm a"/>
            <ColumnDirective width={150} autoFit field="serie" headerText="Serie"/>
            <ColumnDirective width={150} field="tipoDocumentoName" headerText="Tipo Documento"/>
            <ColumnDirective width={150} field="saldo" headerText="Saldo" format="c2" autoFit/>
            <ColumnDirective width={150} field="totalDocumento" headerText="Total documento" format="c2" autoFit/>
            <ColumnDirective width={150} field="moneda" headerText="Moneda" />
            <ColumnDirective width={150} field="diasVencido" headerText="Días vencidos" format="n2" autoFit/>
            <ColumnDirective width={150} headerText="Sin vencer " format="c2"   field="sinVencer" autoFit />
            <ColumnDirective width={150} headerText="1 - 30"      format="c2"  field="dias030" autoFit />
            <ColumnDirective width={150} headerText="31 - 60"     format="c2" field="dias3160" autoFit />
            <ColumnDirective width={150} headerText="61 - 90"     format="c2" field="dias6190" autoFit />
            <ColumnDirective width={150} headerText="91 - 121"    format="c2" field="dias91121" autoFit />
            <ColumnDirective width={150} headerText="121 +"       format="c2" field="diasmas121" autoFit />
            <ColumnDirective width={150} headerText="" template={(f: Saldo) => <TrustTemplate {...f} />}/>
          </ColumnsDirective>
          <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                 <AggregateColumnDirective field="sinVencer"  type='Sum'  format='c2' footerTemplate={(e:any)=><AggregateTemplateSum  sumInfo={e} title="total"/>}> </AggregateColumnDirective>
                 <AggregateColumnDirective field='dias030'    type='Sum'  format='c2' footerTemplate={(e:any)=><AggregateTemplateSum  sumInfo={e} title="total"/>}> </AggregateColumnDirective>
                 <AggregateColumnDirective field='dias3160'   type='Sum'  format='c2' footerTemplate={(e:any)=><AggregateTemplateSum  sumInfo={e} title="total"/>}> </AggregateColumnDirective>
                 <AggregateColumnDirective field='dias6190'   type='Sum'  format='c2' footerTemplate={(e:any)=><AggregateTemplateSum  sumInfo={e} title="total"/>}> </AggregateColumnDirective>
                 <AggregateColumnDirective field='dias91121'  type='Sum'  format='c2' footerTemplate={(e:any)=><AggregateTemplateSum  sumInfo={e} title="total"/>}> </AggregateColumnDirective>
                 <AggregateColumnDirective field='diasmas121' type='Sum'  format='c2' footerTemplate={(e:any)=><AggregateTemplateSum  sumInfo={e} title="total"/>}> </AggregateColumnDirective>
              </AggregateColumnsDirective>
            {/*                 
              <AggregateColumnsDirective>

              </AggregateColumnsDirective> */}
            </AggregateDirective>
          </AggregatesDirective>
          <Inject services={[Sort,Aggregate, Page,Toolbar,ExcelExport]} />
        </GridComponent>
  )
}
