import {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {  getRemotePagos } from '../../data/cfdiRemote'
import { Pago } from '../../interfaces'
import { RootState } from '../../store/store'


export const usePagos = () => {
    const [pagos, setPagos] = useState<Pago[]>([])

    const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)
 
   


    useEffect(() => {
        getRemotePagos(auth.token).then(r=> {
           
            setPagos(r)
            console.log(pagos)
        })
           
    }, [auth])


    return {
        pagos
    }
}
