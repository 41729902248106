import { orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';



export const theme = createTheme({
    palette:{
       primary:{
           main:orange[900]
       },
       secondary:{
        main:"#ffff"
       }
    }
});