import { Typography,Box } from '@mui/material'
import { MesVendido } from '../../interfaces'
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2'
ChartJS.register(...registerables);

interface Props
{
  mesesVendidos:MesVendido[]
}
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
     
    },
  },
};

export const GraficaToneladasVendidas = ({mesesVendidos}:Props) => {
  return (
    <>
      <Typography variant="subtitle2" align='center' >Toneladas compradas en los ùltimos {mesesVendidos.length} meses </Typography>
      <Box>
        <Bar 
      width={"400px"} 
      options={options} 
      data={{...mesesVendidos, labels:mesesVendidos.map( m => months[m.month - 1] ), datasets: [{
      label: 'Toneladas compradas',
      data: mesesVendidos.map(m=>m.quantity),
      backgroundColor: 'rgba(255,165, 0,0.5)',
    },
  ],}} />
      </Box>
    </>
   
  )
}

const months:string[] = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
