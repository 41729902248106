
import { Box, Paper, Typography } from "@mui/material";
import { useSaldos } from "../../hooks";
// import { Saldo } from "../../interfaces";
// import { useDownloadFile } from "../../hooks";
// import {  useRef } from "react";
import { green } from "@mui/material/colors";
import currencyFormatter from "currency-formatter";
import { GridSaldos } from "../../components/cfdi/saldos/GridSaldos";

export const Saldos = () => {
  const { saldos } = useSaldos();

  const unitSaldo = saldos?.map(saldo=> saldo.saldo)
  const totalSaldos = unitSaldo?.length>0 ? unitSaldo.reduce((a:number,b:number) => a+b):0
  return (
    <>
      <Paper elevation={4} sx={{maxWidth:1600,margin:"0 auto",borderRadius:6}}>
        <Box padding={4}>
          <Box mb={2}>
            <Typography variant="h6" align="center">
              Mis Saldos
            </Typography>
          </Box>
          <Box sx={{marginY:3}} color={green[900]}>
            <Typography variant="subtitle2" >
                Saldo Total:  {currencyFormatter.format(totalSaldos, { code: "USD",})}
            </Typography>
          </Box>
          {saldos&& <GridSaldos saldos={saldos}/>}
         
        </Box>
      </Paper>
    </>
  );
};





