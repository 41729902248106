import { useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Page,
  Inject,
  Sort,
  Group,
  Filter,
  FilterSettingsModel,
  SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import { Box, Button, Paper, Typography } from "@mui/material";
import { usePagos } from "../../hooks";
import { DownloadOutlined } from "@mui/icons-material";
import { Pago } from "../../interfaces";
import { useDownloadFile } from "../../hooks";

export const Pagos = () => {
  const { pagos } = usePagos();
  //const [colapse, setColapse] = useState<boolean>(true);
  const gridObj = useRef<GridComponent | null>(null);
  const dataBound = () => {
    // if (gridObj && colapse) {
    //     gridObj.current?.groupCollapseAll();
    //     setColapse(false);
    // }
  };

  const filterOptions: FilterSettingsModel = {
    type: 'CheckBox',
  
  };

  const sortingOptions: SortSettingsModel = {

    columns: [{ field: 'numeroDocumento', direction: 'Descending'}, { field: 'fecha', direction: 'Descending' }]
  };

  return (
    <>
       <Paper elevation={4} sx={{maxWidth:1600,margin:"0 auto",borderRadius:6}}>
        <Box padding={4}>
          <Box mb={3}>
            <Typography variant="h6" align="center">
              Mis últimos Pagos
            </Typography>
          </Box>
          {/* <GridComponent allowTextWrap sortSettings={sortingOptions} filterSettings={filterOptions} ref={gridObj} dataSource={pagos} allowFiltering allowSorting allowPaging allowGrouping   */}
           <GridComponent allowTextWrap sortSettings={sortingOptions} filterSettings={filterOptions} ref={gridObj} dataSource={pagos} allowFiltering allowSorting allowPaging allowGrouping groupSettings={{   showDropArea: false,   columns: ["numeroDocumento"], }} 
            pageSettings={{ pageSize: 20 }} dataBound={dataBound} >
            <ColumnsDirective>
              <ColumnDirective width={150} autoFit field="numeroDocumento" headerText="Pago"/>
              <ColumnDirective width={150} autoFit field="documentoRelacionado" headerText="Documento Relacionado"/>
              <ColumnDirective width={150} autoFit field="serie" headerText="Serie"/>
              <ColumnDirective width={150} autoFit field="comentarios" headerText="Comentarios"/>
              <ColumnDirective width={150} autoFit field="fecha" type="datetime" headerText="Fecha Pago" format="dd/MM/yyyy"/>
              <ColumnDirective width={150} autoFit field="total" headerText="Total" format="c2"/>
              <ColumnDirective width={150} autoFit headerText="" template={TrustTemplate}/>
            </ColumnsDirective>
            <Inject services={[Filter, Sort, Page, Group]} />
          </GridComponent>
        </Box>
      </Paper>
    </>
  );
};
const TrustTemplate = (props: Pago) => {
  const { downloadBase64PagoDocumento } = useDownloadFile();
  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={() =>
        downloadBase64PagoDocumento(
          props.documentoRelacionado,
          props.numeroTipoDocumento,
          props.numeroDocumento,
          props.serie
        )
      }
      startIcon={<DownloadOutlined />}
    >
      Descargar
    </Button>
  );
};
