import  {  useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { filterProductsAction } from '../../actions/productos';
import { Filtro } from '../../interfaces';
import { ProductsReducerTypes } from '../../reducers/productsreducer';



export const useFilter = () => {
    

   
    // const [familias, setFamilias] = useState<string[]>([])
    const [filtros, setfiltros] = useState<Filtro>({
        busqueda:"",
        familias:[]
    })

    const didMountRef = useRef(false)
    const {busqueda} = filtros
    
    // const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)
    type AppDispatch = ThunkDispatch<ProductsReducerTypes, any, AnyAction>; 
    const dispatch:AppDispatch = useDispatch();



    
    // useEffect(()=>{
    //     const getFamilias = async ()=>{
    //         const remoteFamilias = await getRemoteFamilias(auth?.token)
    //         setFamilias(remoteFamilias)
    //      }
    //     getFamilias()
    // },[auth])

    
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {  
          if(didMountRef.current) {
            dispatch(filterProductsAction(filtros))
          }else{
            didMountRef.current= true
          }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [filtros,dispatch]);
    
 
   const handleBusqueda = (e:React.ChangeEvent<HTMLInputElement>)=>{ setfiltros(f=> ({...f,busqueda:e.target.value}))}
    
   const changeFamilias = (isChecked:boolean,value:string) => {
        setfiltros((f)=>{
          if(isChecked){
            const familias  = f.familias
            familias.push(value)
            return  ({...f,busqueda:"",familias})
          }else {
            const familias = f.familias.filter(f=>f !== value)
            return {
              ...f,
              familias
            }
          }
        })
  };

  return{ filtros,handleBusqueda,busqueda,changeFamilias}
}
