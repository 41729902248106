import { Box ,TextField} from '@mui/material'
import { useFilter } from '../../hooks';


export const Filters = () => {

 


  const {busqueda,handleBusqueda} =  useFilter()

   
  return (
   <Box sx={{position:"sticky",top:90}}>
      {/* <Typography variant="subtitle2" align="center">Busqueda</Typography> */}
      <Box my={2}>
         <TextField value={busqueda}  onChange={handleBusqueda} variant="outlined" label="Nombre del Producto" size="small" fullWidth/>
      </Box>
    {/* <Typography variant="caption">Familia</Typography>  */}
    {/* <FormGroup >
       {familias.map(familia=>  <FormControlLabel key={familia}  control={<Checkbox  
        onChange={(e)=>{ changeFamilias(e.target.checked,familia)}}
       
       />} label={familia} />)}
    </FormGroup>  */}
   </Box>

  )
}
