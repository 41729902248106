
import { CssBaseline } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme/theme';
import { registerLicense } from "@syncfusion/ej2-base";
import {setCulture,L10n} from '@syncfusion/ej2-base';
import es from './culture/es.json'

//Syncfusion Imports
import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

import "react-multi-email/dist/style.css";
registerLicense(
  //"ORg4AjUWIQA/Gnt2VVhiQlFadVZJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkJhWH5fcnZVQWJYVkU="
  // "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdk1iWX5dcX1VQmJVWEI="
  "ORg4AjUWIQA/Gnt2V1hhQlJNfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5UdEViXXtecnVdT2Re"
);
L10n.load(es)
setCulture("es")

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <CssBaseline/>
    <ThemeProvider theme={theme}>
     <App />
    </ThemeProvider>
  </React.StrictMode>
);