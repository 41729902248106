import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack'

import { Button, TextField,Box, Paper, Typography } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { insertRemoteComentario} from '../../data/userInfo'
import { RootState } from '../../store/store';

export const Comentarios = () => {

  const { enqueueSnackbar } = useSnackbar();
  const {token} =  useSelector<RootState,RootState["auth"]>(state=> state.auth)
  const [comentario, setComentario] = useState({
    comentario:""
  })

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (comentario.comentario.length>0&&comentario.comentario.length<=500) {
      await insertRemoteComentario(token,comentario.comentario)
      setComentario({comentario:""})
      enqueueSnackbar("¡Hemos recibido sus comentarios, gracias! ", { variant: "success" });

    }
  };

  return (
    <Paper elevation={4} sx={{maxWidth:1200,margin:"0 auto",borderRadius:3}}>
      <Box sx={{padding:3}} >
         <Typography textAlign={"center"} variant="subtitle2" sx={{marginTop:3}}>
             En Pegaduro tu opinión es importante. Ayúdanos con sugerencias o a reportar alguna 
             mala práctica o mala atención por parte de nosotros. Este reporte llega de forma 
             directa a Dirección General 
         </Typography>
         <Box component="form" noValidate onSubmit={handleSubmit}>
            <Box sx={{marginTop:4}} >
               <TextField
                 id="comentario"
                 label="Comentarios"
                 multiline
                 value={comentario.comentario}
                 fullWidth
                 name="comentario"
                 variant="outlined"
                 rows={10}
                 helperText="max 500"
                 onChange={(ev)=>{ setComentario(e=>({...e,[ev.target.name]:ev.target.value}))}}
               />
            </Box>
            <Box  sx={{marginY:4,display:"flex",flexDirection:"row-reverse"}}>
              <Button  type="submit" endIcon={<SendOutlinedIcon/>}   variant="contained">Enviar</Button>
            </Box>
         </Box>
      </Box>
    </Paper>
  );
};
