
import { useSelector } from 'react-redux';
import { getRemoteBase64,getRemotePagoDocumento } from '../../data/cfdiRemote';
import { FnNuevoArchivo } from '../../helpers/downloadFiles';
import { RootState } from '../../store/store';

export const useDownloadFile = () => {

    const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)

    const downloadBase64 = async (docNum:string,tipo:number,serie:string) => {
      const base64 = await  getRemoteBase64(auth.token,docNum,tipo)
      FnNuevoArchivo(base64,docNum.toString()+"-"+serie)
    }


    const downloadBase64PagoDocumento = async (docNum:number,tipo:number,numeroPago:number,serie:string)=> {
      const base64 = await getRemotePagoDocumento(auth.token,docNum,numeroPago,tipo)
      FnNuevoArchivo(base64,docNum.toString()+"-"+serie)
    }

    return {
        downloadBase64,
        downloadBase64PagoDocumento
    }

}
