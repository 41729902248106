import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getRemoteNotasCredito } from '../../data/cfdiRemote'
import {  NotaCredito } from '../../interfaces'
import { RootState } from '../../store/store'


export const useNotaCredito = () => {
    const [notasCredito, setNotasCredito] = useState<NotaCredito[]>([])

    const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)
    
    useEffect(() => {
        
        getRemoteNotasCredito(auth.token).then(nc=> setNotasCredito(nc)).catch(e=>{ 
            console.log(e)
        })
           
    }, [auth])


    return {
        notasCredito
    }
}
