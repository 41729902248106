import {Paper,Box,Typography,Divider} from "@mui/material";
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import currencyFormatter  from 'currency-formatter'
import { CustomerInfo } from '../../interfaces'
interface Props
{
    customer?:CustomerInfo
}
export const SaldoCard = ({customer}:Props) => {
  return (
  <Paper elevation={4} sx={{borderRadius:4}}>
    <Box sx={{ padding: 2 ,height:220}}>
      <Box >
        <Box >
          <PaidOutlinedIcon  sx={{ width: "100%", fontSize: 50 }} color="primary"/>
        </Box>
        <Box>
          <Typography variant="subtitle2" align="center">
            {"Estado de cuenta"}
          </Typography>
          <Box mb={2}>
            <Divider />
          </Box>
          <Typography variant="body2" align="center">Saldo total: {customer&&currencyFormatter.format(customer.saldo,{code:"USD"})  }</Typography>
          <Typography variant="body2" align="center">Saldo vencido: {customer&&currencyFormatter.format(customer.saldoVencido,{code:"USD"})  }</Typography>
          <Typography variant="body2" align="center">Saldo por vencer: {customer&&currencyFormatter.format(customer.saldoPorVencer,{code:"USD"})  }</Typography>
          <Typography variant="body2" align="center">Anticipo:{customer&&currencyFormatter.format(customer.anticipos,{code:"USD"})  }  </Typography>
        </Box>
      </Box>
    </Box>
  </Paper>
  )
}
