import { getLocalStorageToke } from "../helpers/localStorage";
import { AuthState } from "../interfaces/auth";


export type AuthReducerTypes = 
|{type:"[AUTH] LogIng",payload:{token:string}}
|{type:"[AUTH] LogOut"}
|{type:"[AUTH] ChangePassword",payload:{token:string}}
|{type:"[AUTH] Error",payload:{message:string }}



export enum AuthTypes{
    LogIn=  "[AUTH] LogIng",
    LogOut = "[AUTH] LogOut",
    ChangePassword = "[AUTH] ChangePassword",
    Error=  "[AUTH] Error"
}


export const authReucer = (state:AuthState =getLocalStorageToke(),action:AuthReducerTypes):AuthState => {

    switch (action.type) {
        case AuthTypes.LogIn:
            return {token:action.payload.token!,isLoged:true,haveToChange:false}
        case "[AUTH] LogOut":  
            return {token:"",isLoged:false,haveToChange:false,error:undefined}
        case "[AUTH] ChangePassword":
            return {...state,token:action.payload.token!,haveToChange:true}
        case "[AUTH] Error":
            return {...state,error:action.payload.message}
        default:
            return state
    }
}