import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "../../components/login/CopyRigth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LogInAction } from "../../actions/auth";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AuthReducerTypes } from "../../reducers/authreducer";
import { RootState } from "../../store/store";
import { ModalChangePassword } from "../../components/login/ModalChangePassword";
export const LogIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  type AppDispatch = ThunkDispatch<AuthReducerTypes, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();
  const auth = useSelector<RootState, RootState["auth"]>((state) => state.auth);
  const [open, setopen] = useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const usuario = data?.get("email")?.toString();
    const password = data.get("password")?.toString();
    if (usuario && password) {
      dispatch(LogInAction(usuario, password));
    }
  };

  useEffect(() => {
    if (auth.haveToChange) {
      enqueueSnackbar("Es necesario reiniciar su contraseña", {
        variant: "warning",
      });
      navigate("/auth/change-password", { replace: false });
    } else if (auth.error) {
      enqueueSnackbar(auth.error, { variant: "error" });
    }
  }, [auth, navigate, enqueueSnackbar]);

  const handleClose = () => {
    setopen(false);
  };

  const handleResetPassword = () => {
    setopen(true);
  };

  return (
    <>
      <ModalChangePassword open={open} handleClose={handleClose} />
      <Box
        sx={{mx: 4,display: "flex",flexDirection: "column",alignItems: "center",height: "100vh",justifyContent: "center",}}>
        <Avatar
          sx={(theme) => ({
            m: 1,
            bgcolor: "primary.main",
            [theme.breakpoints.down("sm")]: {
              display: "none",
            },
          })}
        >
          <LockOutlinedIcon />
        </Avatar>
        <Box
          sx={(theme) => ({
            display: "none",
        
            [theme.breakpoints.down("sm")]: { 
              display: "flex",
              justifyContent: "center",
              alignContent: "center ",
            },
          })}
        >
          <img
            src="https://www.pegaduro.com.mx/img/LogoPegaduro.png"
            alt="logo-pegaduro"
            style={{ maxWidth: "40%" }}
          />
        </Box>
        <Typography variant="h6" sx={{marginY:1}}>
          Portal de clientes
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Usuario"
            name="email"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Button type="submit" fullWidth variant="contained">
                Iniciar Sesión
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="button"
                onClick={handleResetPassword}
                variant="text"
                fullWidth
                size="small"
              >
                Restablecer contraseña
              </Button>
            </Grid>
          </Grid>

          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </>
  );
};
