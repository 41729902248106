import {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getRemoteSaldos } from '../../data/cfdiRemote'
import {  Saldo } from '../../interfaces'
import { RootState } from '../../store/store'


export const useSaldos = () => {
    const [saldos, setSaldos] = useState<Saldo[]>([])

    const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)
 
    useEffect(() => {
        getRemoteSaldos(auth.token).then(saldos=> {
            const newSaldos:Saldo[] =  saldos.map(saldo=> {
                const { diasVencido ,saldo:pendiente} = saldo
                return  {
                    ...saldo,
                    sinVencer:diasVencido<=0?pendiente:0,
                    dias030 :diasVencido>=1&&diasVencido<=30?pendiente:0,
                    dias3160:diasVencido>=31&&diasVencido<=60?pendiente:0,
                    dias6190:diasVencido>=61&&diasVencido<=91? pendiente:0,
                    dias91121:diasVencido>=91&&diasVencido<=121?pendiente:0,
                    diasmas121:diasVencido>121 ?pendiente :0
                }
            })
            setSaldos(newSaldos)
        })
           
    }, [auth])


    return {
        saldos
    }
}
