import { ProductoList } from "../components/products/ProductoList"
import { ProductoCarrito } from "../interfaces"


export const calularDescuentoLinea = (product:ProductoCarrito) => {

  let descunetoLinea = 0;

  if(!product.precio.precioEspecial){ 

      if(product.productoDescuento != null && product.cantidad >= (product.productoDescuento.payFor + product.productoDescuento.forFree)){

        if(product.productoDescuento.descuento <= 0){
          const cantidadPayFor = Math.floor(product.cantidad / (product.productoDescuento.payFor + product.productoDescuento.forFree));
          descunetoLinea = (cantidadPayFor * product.productoDescuento.forFree ) * product.precio.precio;  
        }
        else{
          descunetoLinea = ( product.cantidad * product.precio.precio ) * product.productoDescuento.descuento;
        }

      }else{
        if(product.productoDescuentoPorListas != null){
        let porcentajeDescuento = 0;
        product.productoDescuentoPorListas.forEach( descuento => {
          if( product.cantidad >= descuento.apartirDe ){
            porcentajeDescuento = descuento.descuento;
          }
        });
        descunetoLinea = ( product.cantidad * product.precio.precio ) * porcentajeDescuento;
      }
    }
      }

  return {
    descunetoLinea
  }
}
