
import { httpClient } from "../config/axiosconfig"
import { Factura, NotaCredito, Pago, Saldo } from "../interfaces"


export const getRemoteFacturas = async (token:string):Promise<Factura[]> => {

    const response = await httpClient.get<Factura[]>("CFDI/facturas",{headers:{"Authorization":token}})

    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}
export const getRemoteNotasCredito= async (token:string):Promise<NotaCredito[]> => {
   
    const response = await httpClient.get<NotaCredito[]>("CFDI/notas-credito",{headers:{"Authorization":token}})
  
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}
export const getRemoteSaldos = async (token:string):Promise<Saldo[]> => {

    const response = await httpClient.get<Saldo[]>("CFDI/saldos",{headers:{"Authorization":token}})
    
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}
export const getRemotePagos = async (token:string):Promise<Pago[]> => {

    const response = await httpClient.get<Pago[]>("CFDI/pagos",{headers:{"Authorization":token}})
    
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}


export const getRemoteBase64 = async (token:string,docNum:string,tipo:number):Promise<string> => {

 
    const response = await httpClient.get<string>(`CFDI/download-cfdi?docNum=${docNum}&tipoDocumento=${tipo}`,{headers:{"Authorization":token}})
    
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}


export const getRemotePagoDocumento = async(token:string,docNum:number,numeroPago:number,tipo:number):Promise<string> => {

  
    const response = await httpClient.get<string>(`CFDI/download-pago-documento?docNum=${docNum}&numeroPago=${numeroPago}&tipoDocumento=${tipo}`,{headers:{"Authorization":token}})
    
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}

