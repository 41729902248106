import { useEffect, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";
import { ProductoCarrito, Promocion } from "../../interfaces";
import currencyFormatter from "currency-formatter";
import { DeleteOutlined, ModeEditOutline } from "@mui/icons-material";
import { ThunkDispatch } from "redux-thunk";
import { CarritoReducerTypes } from "../../reducers/carritoreducer";
import { AnyAction } from "redux";
import { useDispatch } from "react-redux";
import { deleteItemCarritoAction } from "../../actions/carrito";
import { ModalProduct } from "../products/ModalProduct";
import { getDescuentosProducto } from "../../helpers/descuentos";
import { ProductoList } from "../products/ProductoList";

interface Props {
  product: ProductoCarrito;
}

export const CardProductCarritoV2 = ({ product }: Props): JSX.Element => {
  const [open, setopen] = useState<boolean>(false);
  type AppDispatch = ThunkDispatch<CarritoReducerTypes, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();

  const handleDelete = () => {
    dispatch(deleteItemCarritoAction(product));
  };

  const handleClose = () => {
    setopen(false);
  };

  const totalCondescuento = (product.cantidad * product.precio.precio) - product.descuentoLinea;
  const iva = totalCondescuento * product.iva;
  const total = totalCondescuento + iva ;

  return (
    <>
      <ModalProduct product={product} open={open} onClose={handleClose} />
      <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2,}}>
        <Box>
          <Typography variant="subtitle2">{product.itemName}</Typography>
          <Box>
            <Box>
            <Typography variant="subtitle2">
                cantidad: x {product.cantidad}
              </Typography>

              {/* <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle2">Subtotal:  </Typography>
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  color={red[900]}
                  sx={{
                    marginLeft:1,
                    textDecoration: product.descuentoLinea
                      ? "line-through"
                      : "none",
                  }}
                >
                  {currencyFormatter.format(( totalSinDescunetos ), {
                    code: "USD",
                  })}
                </Typography>
              </Box> */}

              <Box sx={{ display: "flex", alignItems: "center" }}>

                <Typography variant="subtitle2">Descuento:  </Typography>
                  <Typography
                  variant="body2"
                  fontWeight="bold"
                    color={green[900]}
                    sx={{ marginleft: 1 }}
                  >
                    {currencyFormatter.format( product.descuentoLinea , {
                      code: "USD",
                    })}
                  </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>

                <Typography variant="subtitle2">Impuestos:  </Typography>
                  <Typography
                  variant="body2"
                  fontWeight="bold"
                    color={red[900]}
                    sx={{ marginleft: 1 }}
                  >
                    {currencyFormatter.format( iva , {
                      code: "USD",
                    })}
                  </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>

                <Typography variant="subtitle2">Total:  </Typography>
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    color={green[900]}
                    sx={{ marginleft: 1 }}
                  >
                    {currencyFormatter.format( total , {
                      code: "USD",
                    })}
                  </Typography>
              </Box>

            </Box>
          </Box>
        </Box>
        <Box>
        
          <IconButton color="primary" onClick={handleDelete}>
            <DeleteOutlined />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => {
              setopen(true);
            }}
          >
            <ModeEditOutline />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
