import { ShoppingCartCheckoutOutlined } from '@mui/icons-material'
import { IconButton,Badge } from '@mui/material'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../store/store'

export const CarIconBadge = () => {

const {carrito} =  useSelector<RootState,RootState>(state=> state)
 
  return (
    <IconButton size="large" component={Link} to="carrito">
      <Badge badgeContent={carrito.productos.length} color="error">
        <ShoppingCartCheckoutOutlined color="secondary" />
      </Badge>
    </IconButton>
  
  )
}
