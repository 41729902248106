import  {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getRemoteCustomerInfo,getRemoteMesesVendidos } from '../data/userInfo';
import { CustomerInfo, MesVendido } from '../interfaces';
import { RootState } from '../store/store';

export const useCustomerInfo = () => {
    const [customer, setCustomerInfo] = useState<CustomerInfo>()
    const [mesesVendidos, setMesesVendidos] = useState<MesVendido[]>([])
    const [loading, setloading] = useState(false)
    const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)



    useEffect(() => {

        setloading(true)
        Promise.all([getRemoteCustomerInfo(auth.token),getRemoteMesesVendidos(auth.token,5)])
        .then(result=>{
            setCustomerInfo(result[0])
            setMesesVendidos(result[1])
        })
        .catch(e=>setloading(false))
        // getRemoteCustomerInfo(auth.token).then(r=> { 
        //     setloading(false)
        //     setCustomerInfo(r)
        // })
        // .catch(e=>setloading(false))
        
    }, [auth])
    
    return {
        loading,
        customer,
        mesesVendidos
    }
}

