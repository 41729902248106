import {ColumnDirective,ColumnsDirective,GridComponent,Page,Inject,Sort,} from "@syncfusion/ej2-react-grids";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useFacturas } from "../../hooks";
import { DownloadOutlined } from "@mui/icons-material";
import { Factura } from "../../interfaces";
import { useDownloadFile } from "../../hooks";

export const Facturas = () => {
  const { facturas } = useFacturas();

  return (
    <>
      <Paper elevation={4} sx={{maxWidth:1600,margin:"0 auto",borderRadius:6}}>
        <Box padding={4}>
          <Box mb={3}>
            <Typography variant="h6" align="center">
             Mis Facturas
            </Typography>
          </Box>
          <GridComponent dataSource={facturas} allowSorting allowPaging pageSettings={{ pageSize: 10 ,pageSizes:true }} >
            <ColumnsDirective>
              <ColumnDirective width="150" textAlign="Center" autoFit field="numeroDocumento" headerText="Número Documento" />
              <ColumnDirective width="100" textAlign="Center" autoFit field="serie" headerText="Serie"/>
              <ColumnDirective width="150" textAlign="Center" field="fecha" autoFit type="datetime" headerText="Fecha" format="dd/MM/yyyy"/>
              <ColumnDirective width="150" textAlign="Center" autoFit field="total" headerText="Total" format="c2"/>
              <ColumnDirective width="200" textAlign="Center" autoFit headerText="" template={(f: Factura) => <TrustTemplate {...f} />}/>
            </ColumnsDirective>
            <Inject services={[Sort, Page]} />
          </GridComponent>
        </Box>
      </Paper>
    </>
  );
};
const TrustTemplate = (props: Factura) => {
  const { downloadBase64 } = useDownloadFile();
  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={() => downloadBase64(props.numeroDocumento, 13,props.serie)}
      startIcon={<DownloadOutlined />}
    >
      Descargar
    </Button>
  );
};
