import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getRemoteMisPedidos } from "../../data/pedidosRemote"
import { ReenviarCorreoPedido, UltimosPedidos } from "../../interfaces"
import { RootState } from "../../store/store"
import { reenviarPedido } from '../../data/pedidosRemote';

export const useMisPedidos = () => {


    const [pedidos, setpedidos] = useState<UltimosPedidos[]>([])


    const {auth} = useSelector<RootState,RootState>(state=>state)

    useEffect(() => {
        getRemoteMisPedidos(auth.token).then(p=>setpedidos(p)).catch(e=>console.log(e))
    }, [auth])
    
    const enviarCorreo = async (pedido:ReenviarCorreoPedido):Promise<boolean> => {
        const result = await reenviarPedido(auth.token,pedido)
        return result;
    }
    return{
        pedidos,
        enviarCorreo
    }
}   
