
import { AuthResponse } from "../interfaces"
import { httpClient } from "../config/axiosconfig"




export const loginRemote = async (user:string,password:string):Promise<AuthResponse> =>{
    
    const response = await httpClient.post<AuthResponse>("Access",{user,password})
 
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}

export const changePasswordRemote = async (password:string,newPassword:string,token:string):Promise<string> => {


    const response = await httpClient.post<string>("Access/change-password",{password,newPassword},{headers:{"Authorization":token}})
    if(response.status === 200 ){
        return response.data
    }
    else{
        throw response.data
    }
}

export const resetPasswordRemote = async (user:string):Promise<string> => {




    try {
        const response = await httpClient.post<string>("Access/reset-password",{user,password:"aaaa"})

        if(response.status === 200 ){
            return response.data
        }
        else{
            
            //throw new Error(response.data) 
            return response.data
        }
    } catch (error:any) {
        throw error.response.data
     
    }

}