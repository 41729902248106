import {  Dialog,  DialogContent,  Typography,  Box,  Divider,  Grid,  DialogActions,  Button} from "@mui/material";
import { green } from "@mui/material/colors";
import currencyFormatter from "currency-formatter";
import { UltimosPedidos } from "../../interfaces";
import { PedidoProduct } from "./PedidoProduct";
import { formateDateEs } from "../../helpers/formate-dates";

interface Props {
  onClose: () => void;
  isOpen?: boolean;
  pedido: UltimosPedidos;
}
export const ModalPedidoInfo = ({ isOpen = true, pedido, onClose }: Props) => {
  const { docEntry, fecha } = pedido;
  return (
    <Dialog onClose={onClose} open={isOpen}>
      <DialogContent sx={{ width: 600 }}>
        <Box sx={{ display: "flex", alignContent: "center" }}>
          <img
            src="https://www.pegaduro.com.mx/img/LogoPegaduro.png"
            alt="pegaduro-logo"
            style={{ width: 55, marginRight: 16 }}
          />
          <Box>
            <Typography variant="h5"> Información del pedido</Typography>
            <Typography variant="body2">
              Número de documento: {docEntry === 0? "sin asignar": docEntry}
            </Typography>
            <Typography variant="caption">
              Fecha del pedido: {formateDateEs(fecha)}{" "}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ width: "100%", marginTop: 1, padding: 1 }} />
        <Box sx={{ marginTop: 2 }}>
          <Typography variant="h6">Información de envío</Typography>
          <Grid container sx={{ marginTop: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Calle y Número</Typography>
              <Typography variant="body2">{pedido.direccion?.calle}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Municipio</Typography>
              <Typography variant="body2">
                {pedido.direccion?.municipio}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">Estado</Typography>
              <Typography variant="body2">{pedido.direccion?.estado}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2">País</Typography>
              <Typography variant="body2">{pedido.direccion?.pais}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ width: "100%", marginTop: 1, padding: 1 }} />
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6">Productos</Typography>
          <Box sx={{ marginTop: 2 }}>
            {pedido.productos.map((p) => (
              <PedidoProduct key={p.idProducto} producto={p} />
            ))}
          </Box>
        </Box>
        <Divider sx={{ width: "100%", marginTop: 1, padding: 1 }} />
        <Box sx={{ marginTop: 2 }}>
          <Typography fontWeight="bold">Total: </Typography>
          <Typography color={green[900]}>
            {currencyFormatter.format(pedido.total, { code: "USD",})}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
};
