
import { useSelector } from "react-redux";
import { Box, Paper, Typography } from "@mui/material";
import { RootState } from "../../store/store";
import currencyFormatter from "currency-formatter";
import { CardProductCarrito } from "./CardProductCarrito";
import { Promocion } from "../../interfaces";
import { getDescuentosProducto } from "../../helpers/descuentos";
import { calularDescuentoLinea } from "../../helpers/descuentosV2";
import { green, red } from "@mui/material/colors";
import { CardProductCarritoV2 } from "./CardProductCarritoV2";

interface Props{
  promociones:Promocion[]
}

export const ProductListCar = ({promociones}:Props) => {
  const { carrito } = useSelector<RootState, RootState>((state) => state);

  const subTotal = carrito.productos
    .map((p) => {return p.precio.precio * p.cantidad; })
    .reduce((p, n) => p + n);




  // const descuentoTotalPromociones = carrito.productos.map(p=>{
  //    const descuentoActual =  getDescuentosProducto(promociones,p).descuentoActual
  //    const totalLinea = (p.precio.precio * p.iva + p.precio.precio) * p.cantidad;
  //    if(descuentoActual){
  //       return  (descuentoActual.discount/100) * totalLinea
  //    }
  //    return 0
  // }).reduce((p,n)=> p+n)

  const descuentoTotalPromociones = carrito.productos
  .map((p) => {return p.descuentoLinea})
  .reduce((p, n) => p + n);

  const impuestos = carrito.productos
  .map((p) => {return ((p.precio.precio * p.cantidad) - p.descuentoLinea) * p.iva})
  .reduce((p, n) => p + n); 

  carrito.productos.map( product => {
    product.descuentoLinea = calularDescuentoLinea(product).descunetoLinea;
  })
   
  const total = subTotal + impuestos - descuentoTotalPromociones;
  return (
  
      <Paper>
        <Box p={3}>
          <Typography align="center" variant="h6">
            Mis Productos
          </Typography>
          {carrito.productos
          .sort((a, b) => a.itemName.localeCompare(b.itemName))
          .map((product) => {
            return (
              // <CardProductCarrito key={product.itemCode} product={product} promociones={promociones.filter(p=>p.itemCode === product.itemCode)} />
              <CardProductCarritoV2 key={product.itemCode} product={product} />
            );
          })}
          
          {/*Totales**/}
          <hr/>
          <Box sx={{ display: "flex", flexDirection: "row",marginTop:5 }}>
            <Box>
              <Typography variant="subtitle2">
                Subtotal:{" "}
                {currencyFormatter.format(/*subTotal*/ subTotal, {
                  code: "USD",
                })}
              </Typography>
              <Typography variant="subtitle2">
                Descuento:{" "}
              
                <Typography variant="subtitle2" display="inline" color={green[800]}>   {currencyFormatter.format(descuentoTotalPromociones, {
                  code: "USD",
                })}</Typography>
              </Typography>
              <Typography variant="subtitle2">
                Impuestos:{" "}
                <Typography variant="subtitle2" display="inline" color={red[800]}>   {currencyFormatter.format(impuestos, {
                  code: "USD",
                })}</Typography>
              </Typography>
              <Typography variant="h6" display="inline" >
                Total:{" "}
                <Typography variant="h6" display="inline" color={green[600]}> {currencyFormatter.format(total , {
                  code: "USD",
                })}</Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
  );
};

