import { useEffect } from "react";
import { AnyAction } from "redux";
import { Box } from "@mui/material";
import { ProductoList } from "../../components/products/ProductoList";
import PropagateLoader from "react-spinners/PropagateLoader";
import { Filters } from "../../components/products/Filters";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ThunkDispatch } from "redux-thunk";
import { ProductsReducerTypes } from "../../reducers/productsreducer";

import { getProductsAction } from "../../actions/productos";

export const Catalogo = () => {
  const { products, auth } = useSelector<RootState, RootState>(
    (state) => state
  );
  type AppDispatch = ThunkDispatch<ProductsReducerTypes, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {dispatch(getProductsAction(auth.token));}, [auth, dispatch]);
  useEffect(() => {window.scrollTo({  top: 0,  behavior: "smooth",});}, [products]);
  return (
    <Box padding={1}>
     
        <Box>
          <Filters />
        </Box>
        <Box>
          {products.loading ? (
            <Box sx={{display: "flex",justifyContent: "center",textAlign: "Center",marginTop: 30,}}>
              <PropagateLoader color="#fe5000" />
            </Box>
          ) : (
            <ProductoList />
          )}
        </Box>
    </Box>
  );
};
