import {Box, Grid, Dialog, TextField, Typography, Button, DialogActions } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react';
import {  Producto } from '../../interfaces'
import Logo from "../../assets/img/logoPegaduro.png";
import { AddShoppingCartOutlined } from '@mui/icons-material';
import { modificarCarritoAction } from '../../actions/carrito';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { CarritoReducerTypes } from '../../reducers/carritoreducer';
import { RootState } from '../../store/store';
interface Props {
    open:boolean,
    product:Producto,
    onClose:()=>void
}

export const ModalProduct = ({ open,product,onClose}:Props) => {

  const { urL_Publica, urlImagen, itemName, descCorta } = product;
  const url = urL_Publica && urlImagen? urL_Publica + urlImagen : Logo;

   const {carrito} =  useSelector<RootState,RootState>(state => state)

  const [cantidad, setCantidad] = useState<number>(1)
  type AppDispatch = ThunkDispatch<CarritoReducerTypes, any, AnyAction>; 
  const dispatch:AppDispatch = useDispatch();
  
  const handleAdd= ()=>{
     if(!cantidad)
         return
    if(cantidad>0){
        dispatch( modificarCarritoAction(product,Number(cantidad)))
        onClose()
    }
  }


  const handleChangeCantidad = (e:ChangeEvent<HTMLInputElement>)=>{
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
       setCantidad(Number(e.target.value))
    }
  }


  useEffect(() => {
    
     const productoCarrito =  carrito.productos.find(p=>p.itemCode===product.itemCode)
     if(productoCarrito){
        setCantidad(productoCarrito.cantidad)
     }
  }, [carrito,product])
  
  return (
    <Dialog onClose={onClose} open={open}>
        <Box p={4}>
             <Grid container spacing={4}>
                 <Grid item xs={12} md={4}>
                     <Box sx={{display:"flex",justifyContent:"center"}}>
                         <img src={url!=="https://www.pegaduro.com.mx/PRODUCTOS/NOT_FOUND_IMAGE.jpg"?url:Logo} style={{maxWidth:"100%",height:120}} alt={product.itemName}/>
                     </Box>
                 </Grid>
                 <Grid item xs={12}  md={8}>
                     <Box sx={{height:"100%",  display:"flex",flexDirection:"column",justifyContent:"space-between"}}>
                         <Box>
                             <Typography variant="subtitle2" >{itemName}</Typography>
                            <Box sx={{marginTop:2}}> 
                                <Typography variant="body2" >{descCorta === "" ?"Descripción no disponible":descCorta}</Typography>
                            </Box>
                         </Box>

                         <Box mt={3} >
                            
                             <TextField 
                                value={cantidad}  
                                onChange={handleChangeCantidad}
                                size='small' 
                                label="Cantidad"
                                placeholder='Cantidad'
                                type="number"
                                variant="outlined"
                              />
                         </Box>
                     </Box>
                 </Grid>
             </Grid>
        </Box>

    <DialogActions>
          <Button onClick={onClose}>Cancelar</Button>
          <Button startIcon={<AddShoppingCartOutlined/>} onClick={handleAdd}>Agregar</Button>
    </DialogActions>
    </Dialog>
  )
}



