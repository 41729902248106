import {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getRemoteFacturas } from '../../data/cfdiRemote'
import { Factura } from '../../interfaces'
import { RootState } from '../../store/store'


export const useFacturas = () => {
    const [facturas, setFacturas] = useState<Factura[]>([])

    const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)

    
    useEffect(() => {
        getRemoteFacturas(auth.token).then(r=> {
            setFacturas(r)
        })
    }, [auth])


    return {
        facturas
    }
}
