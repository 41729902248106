import  {  useEffect, useState } from 'react'
import { AnyAction } from 'redux'
import { ThunkDispatch } from "redux-thunk";
import { useDispatch, useSelector } from 'react-redux'
import  { v4 as uuidv4 } from 'uuid'
import { useSnackbar } from 'notistack'
import { postOrdenVenta } from '../../data/pedidosRemote'
import { getRemoteDirecciones } from '../../data/userInfo'
import { Direccion, Pedido, ProductoPedido, Promocion } from '../../interfaces'
import { RootState } from '../../store/store'
import { clearCarritoAction } from '../../actions/carrito'
import { CarritoReducerTypes } from '../../reducers/carritoreducer';
import { getRemotePromociones } from '../../data/products';
import { getDescuentosProducto } from '../../helpers/descuentos';
import { calularDescuentoLinea } from "../../helpers/descuentosV2";

export const usePedido = () => {
    
    const { enqueueSnackbar } = useSnackbar();
    const [direcciones, setdirecciones] = useState<Direccion[]>([])
    const [promociones,setpromociones] = useState<Promocion[]>([])
    const {carrito,auth} = useSelector<RootState,RootState>( state  => state)
    type AppDispatch = ThunkDispatch<CarritoReducerTypes, any, AnyAction>; 
    const dispatchCarrito:AppDispatch = useDispatch();
    const [form, setform] = useState({
        comentarios:"",
        direccion:""
      })
    const [statusPedido,setStatusPedido] =useState({
        loading:false,
        succes:false
      })  
  
    const {productos} = carrito



    useEffect(() => {
       getRemoteDirecciones(auth.token)
       .then(d=> setdirecciones(d))
       getRemotePromociones(auth.token)
       .then(p=>setpromociones(p))
    }, [auth])

    const sendPedido = async ()=>{
    
        setStatusPedido(lo=>({...lo,loading:true}))
        const selectedDireccion  = direcciones.find(d=>d.address === form.direccion)
        if(!selectedDireccion){
         enqueueSnackbar("Por favor seleccione una dirección", { variant: "error" });
         setStatusPedido({loading:false,succes:false})
         return 
        }

        
        const carrito:ProductoPedido[] = productos.map(p=>{
            ///CAALCULAR NUEVOS IMPUESTOS
        //const total = (p.precio.precio * p.iva + p.precio.precio) * p.cantidad 
        const totalCondescuento = (p.cantidad * p.precio.precio) - p.descuentoLinea;
        const iva = totalCondescuento * p.iva;
        const total = totalCondescuento + iva ;
        //const descuentoProducto = getDescuentosProducto(promociones.filter(pro=>pro.itemCode === p.itemCode),p)
        const descuentoProducto = calularDescuentoLinea(p).descunetoLinea;
        //const descuentoEfectivo = descuentoProducto.descuentoActual? ((descuentoProducto.descuentoActual.discount/100)*total):0
        const descuentoEfectivo = descuentoProducto? ((descuentoProducto/100)*total):0
         return ({
            itemCode: p.itemCode,
            nombreProducto:p.itemName,
            cantidad:  p.cantidad,
            precioUnitario: p.precio.precio,
            impuestoPorcenaje: p.iva,
            moneda: p.precio.moneda,
            impuesto: (p.precio.precio * p.iva ) *p.cantidad,
            subTotal: (p.precio.precio *p.cantidad),
            descuentoPorcentaje: ( descuentoProducto * 100 ) / (p.cantidad * p.precio.precio),
            // descuento:descuentoProducto.descuentoActual? descuentoProducto.descuentoActual.discount : 0 ,
            descuento:descuentoProducto,
            descuentoEfectivo,
            total: total ,
           })
        })
        
        const pedido:Pedido = {
         guid:uuidv4(),
         carrito,
         direccionEnvio:selectedDireccion,
         comentarios:form.comentarios,
        }
        
        try {
          await postOrdenVenta(auth.token,pedido)
          enqueueSnackbar("La orden de venta se creo con éxito!", { variant: "success" });
          setStatusPedido({loading:false,succes:true})
          dispatchCarrito(clearCarritoAction())
        } catch (error) {
          enqueueSnackbar("Error al crear la orden de venta",{variant:"error"})
          setStatusPedido({loading:false,succes:false})
        }
         
     }

     
    const handleForm = (value:string,name:string)=>{
      setform(f=>({...f,[name]:value}))
    }


    return {
        direcciones,
        statusPedido,
        handleForm,
        sendPedido,
        form,
        productos,
        promociones
    }

}
