import {  useEffect} from "react";
import {  Grid, Paper} from "@mui/material";

import { Outlet, useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";


const AuthLayout = () => {



  const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)
  let navigate = useNavigate();

  useEffect(() => {
    if(auth.isLoged){
      navigate("/",{replace:true})
    }
  }, [auth,navigate])
  


  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage:
            "url(https://tienda.pegaduro.com.mx/static/media/LogoPegaduro.7c045673.png)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundPosition: "center",
        }}
      />
      <Grid height="100vh" item xs={12} sm={8} md={6} component={Paper} elevation={6} square>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
