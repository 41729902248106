import { useState, useEffect } from "react";
import { AppBar, useTheme } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
//import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
//import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Outlet, useNavigate } from "react-router-dom";
import { Divider, Drawer, IconButton } from "@mui/material";
 import { CarIconBadge } from "../../components/menu/CarIconBadge";
import { ListMenuMain } from "../../components/menu/ListMenuMain";
import { ChevronLeft, ChevronRight, Menu } from "@mui/icons-material";
import { MenuUser } from "../../components/menu/MenuUser";




const MainLayout = () => {
  const { auth } = useSelector<RootState, RootState>((state) => state);

  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!auth.isLoged) {
      navigate("auth", { replace: true });
    }
  }, [auth, navigate]);

  return (
    <Box sx={{ display: "flex" }}>
   
        <AppBar>
          <Toolbar>
            <Box sx={{display: "flex",justifyContent: "space-between",width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start"
                  sx={{
                    ...(open && { display: "none" }),
                  }}
                >
                  <Menu />
                </IconButton>
                <Typography sx={{marginLeft:2}} variant="h5"  >
                  {"Mi portal de cliente"}
                </Typography>
              </Box>
              <Box>
                <CarIconBadge />
              </Box>
            </Box>
          </Toolbar>
        
        </AppBar>
      <Drawer open={open} onClose={handleDrawerClose}>
        <Box sx={{ width: 280 }}>
          <Box sx={{display:"flex",justifyContent:"space-between"}}>
            <img
              src="https://www.pegaduro.com.mx/img/LogoPegaduro.png"
              style={{ width: 60, height: 80, margin: 20 }}
              alt="logo-empresa"
            />
            <IconButton  onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </Box>
        </Box>
        <Divider />
          <ListMenuMain open={open} handleClose={handleDrawerClose}/>
        <Divider />
        <MenuUser open={open} handleClose={handleDrawerClose}/>
      </Drawer>
      <Box component="main" sx={{ marginTop: 10,width:"100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
};
export default MainLayout;
