import { AccountBalanceOutlined, CreditCardOutlined, LocalAtmOutlined, RequestPageOutlined,ShoppingBagOutlined,PriceCheckOutlined } from "@mui/icons-material";
import { Facturas, NotasCredito, Pagos, Saldos } from "../pages/cfdi";
import { Catalogo } from "../pages/products";
import { Comentarios } from "../pages/user/Comentarios";
import { MisPedidos } from "../pages/user/MisPedidos";
import AccessibilityNewOutlinedIcon  from '@mui/icons-material/AccessibilityNewOutlined';

export interface Route{
    to:string;
    name:string;
    path:string;
    Icon?:any;
    Component:()=>JSX.Element,
    isColored?:boolean
    
}



export const rutasMain:Route[] = [
    {
        to:"/productos-catalogo",
        path:"productos-catalogo",
        name:"Tomar Pedido",
        Component:Catalogo,
        Icon:ShoppingBagOutlined
    },
    {
        to:"/facturas",
        path:"facturas",
        name:"Facturas",
        Component:Facturas,
        Icon:RequestPageOutlined
    },
    {
        to:"/notas-credito",
        path:"notas-credito",
        name:"Notas de Crédito",
        Component:NotasCredito,
        Icon:CreditCardOutlined
    },
    {
        to:"/saldos",
        path:"saldos",
        name:"Saldos",
        Component:Saldos,
        Icon:LocalAtmOutlined
    },{
        to:"/pagos",
        path:"pagos",
        name:"Pagos",
        Component:Pagos,
        Icon:AccountBalanceOutlined
    }
    ,{
        to:"/mis-pedidos",
        path:"mis-pedidos",
        name:"Mis Pedidos",
        Component:MisPedidos,
        Icon:PriceCheckOutlined,
        
    },
    {
        to:"/comentarios-sugerencias",
        path:"comentarios-sugerencias",
        name:"Tu opinión es importante",
        Component:Comentarios,
        Icon:AccessibilityNewOutlinedIcon,
        isColored:true
    },
]