import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { setCarritoLocalStorage } from "../helpers/localStorage";
import { CarritoState, Producto, ProductoCarrito } from "../interfaces";
import { CarritoReducerTypes } from "../reducers/carritoreducer";
import { store } from "../store/store";



export const modificarCarritoAction: ActionCreator<ThunkAction<Promise<Action<CarritoState>>, CarritoReducerTypes, void,any>> = (producto:Producto,cantidad:number) => {
    return async (dispatch: Dispatch<CarritoReducerTypes>): Promise<Action> => {
      const { carrito} = store.getState()
      const item = carrito.productos.find(c=> c.itemCode === producto.itemCode,cantidad);
      if(item){
        
           const newCarrito = carrito.productos.filter(c=>c.itemCode !== producto.itemCode);
           item.cantidad = cantidad
           newCarrito.push(item)
           setCarritoLocalStorage({productos:newCarrito});
        
           return dispatch({type:"[CARRITO] Change",payload:{carrito:{productos:newCarrito}}});
      }else{
          const newCarrito = carrito.productos
          newCarrito.push({...producto,cantidad})
          setCarritoLocalStorage({productos:newCarrito.sort()});
    
          return dispatch({type:"[CARRITO] Change",payload:{carrito:{productos:newCarrito}}});
      }
    };
  };

export const clearCarritoAction:ActionCreator<ThunkAction<Promise<Action<CarritoState>>, CarritoReducerTypes, void,any>>= () => {
    return async (dispatch: Dispatch<CarritoReducerTypes>): Promise<Action> => {
      setCarritoLocalStorage({productos:[]});
      return  dispatch({"type":"[CARRITO] Clear"})      
    };
};

export const deleteItemCarritoAction:ActionCreator<ThunkAction<Promise<Action<ProductoCarrito[]>>,CarritoReducerTypes,void,any>> = (deletedProduct:ProductoCarrito) => {
  return async (dispatch:Dispatch<CarritoReducerTypes>):Promise<Action> =>{
    const {carrito} = store.getState()

    const carritoFiltered =  carrito.productos.filter(p=>p.itemCode!== deletedProduct.itemCode)

    setCarritoLocalStorage({productos:carritoFiltered});
    return  dispatch({type:"[CARRITO] DeleteItem",payload:{carrito:{productos:carritoFiltered}}})
  }
}
