
import {  Grid, Pagination,Box } from "@mui/material";
import { useSelector,useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { changePageProductsAction } from "../../actions/productos";
import { ProductsReducerTypes } from "../../reducers/productsreducer";
import { RootState } from "../../store/store";

import { ProductCard } from "./ProductCard";




export const ProductoList = () => {


  const  {auth,products} = useSelector<RootState,RootState>( state => state)
  
  type AppDispatch = ThunkDispatch<ProductsReducerTypes, any, AnyAction>; 
  const dispatch:AppDispatch = useDispatch();
 
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    dispatch(changePageProductsAction(value,auth.token))
  };
  return (
    <>
      <Grid container spacing={1}>
        {products?.products?.map((product) => (
          <Grid key={product.itemCode} item xs={12} md={4} lg={4} xl={3}>
            <ProductCard  product={product} />
          </Grid>
        ))}
      </Grid>
      <Box marginY={4} sx={{display:"flex",justifyContent:"center"}} >
        <Pagination page={products.page} count={products.pages} color="primary" onChange={handleChangePage} />
      </Box>
    </>
  );
};
