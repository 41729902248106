import { Box, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { Direccion } from "../../interfaces";

interface Props {
  direcciones: Direccion[];
  direccion:string;
  handleForm:(value:string,name:string)=>void
  comentario:string;
}
export const AddressCard = ({ direcciones,handleForm,direccion,comentario }: Props) => {
  return (

      <Paper >
        <Box p={3}>
          <Typography align="center" variant="subtitle2">
            Información del pedido
          </Typography>
          <Box mb={5}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Dirección</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Dirección"
                  name="direccion"
                  onChange ={(e)=>{handleForm (e.target.value,e.target.name)}}
                  value={direccion}>
                  {direcciones.map(d=>  <MenuItem key={d.address} value={d.address}>{`${d.street}`}</MenuItem>)}
                </Select>
            </FormControl>
          </Box>
          <TextField
            id="outlined-multiline-static"
            label="Comentarios"
            multiline
            fullWidth
            value={comentario}
            variant="outlined"
            rows={5}

            name="comentarios"
            onChange ={(e)=>{handleForm (e.target.value,e.target.name)}}
            placeholder="¿Alguna nota en su orden de compra?"
          />
        </Box>
      </Paper>
  );
};
