import { combineReducers, compose, createStore,applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { AuthState, CarritoState, ProductosState } from "../interfaces";
import { authReucer } from "../reducers/authreducer";
import { carritoReducer } from "../reducers/carritoreducer";
import { productsReducer } from "../reducers/productsreducer";


declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export interface RootState {
  auth:AuthState,
  carrito:CarritoState,
  products:ProductosState
}

const reducers = combineReducers<RootState>({
    auth: authReucer,
    carrito: carritoReducer,
    products:productsReducer
  });
  

export const store  = createStore(
      reducers,
      composeEnhancers(applyMiddleware(thunk))
  )