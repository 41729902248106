import { useState, forwardRef } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Grid,
  Typography,
  Alert,
  AlertTitle,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { resetPasswordRemote } from "../../data/authRemote";
import {  LockResetOutlined } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  open: boolean;
  handleClose: () => void;
}

export const ModalChangePassword = ({ open, handleClose }: Props) => {
  const [user, setuser] = useState<{ usuario: string }>({
    usuario: "",
  });
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();

  const actualizarPassword = async () => {
    setloading(true);
    try {
      const newPassword = await resetPasswordRemote(user.usuario);
      setNewPassword(newPassword);
      setuser({ usuario: "" });
    } catch (error) {
      seterror(error as string);
    }
    setloading(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems:"center"
          }}
        >
          <LockResetOutlined color="primary" sx={{marginRight:2}} />
          {"Restablecer contraseña"}
        </DialogTitle>
        <DialogContent >
          <Box sx={{ mt: 2 }}>
            <TextField fullWidth label="Clave de cliente" variant="outlined" name="usuario" value={user.usuario} 
                        onChange={(e) => {   setuser((u) => ({ ...u, [e.target.name]: e.target.value })); }}/>
          </Box>
           
          {newPassword ? (
            <Box>
              <Alert>
                <AlertTitle>
                  <Typography variant="subtitle2">
                    {" "}
                    Su contraseña se actualizao de forma exitosa!{" "}
                  </Typography>
                </AlertTitle>
                <Typography variant="body2">
                  Su nueva contraseña es : {newPassword}
                </Typography>
              </Alert>
              <Box sx={{ mt: 2 }}>
                <Button
                  fullWidth
                  onClick={handleClose}
                  variant="text"
                  size="small"
                >
                  cerrar
                </Button>
              </Box>
            </Box>
          ) : (
            <>
              {!loading ? (
                <>
                  {error && 
                  <Alert severity="error">  
                   <AlertTitle>
                    <Typography variant="subtitle2">
                      Su contraseña no se pudo actualizar!
                    </Typography>
                    <Typography variant="body2">
                      Inténtelo más tarde, si el problema persiste, comuníquese con su asesor Pegaduro
                    </Typography>
                   </AlertTitle>
                 
                  </Alert>}
                  <Grid container spacing={3} sx={{ marginTop: 0 }}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        onClick={handleClose}
                        variant="text"
                        size="small"
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        onClick={actualizarPassword}
                        variant="text"
                        size="small"
                      >
                        Actualizar
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                  <ClipLoader color="#fe5000" size={50} />
                </Box>
              )}
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};
