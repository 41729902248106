import { CarritoState } from "../interfaces"
import { AuthState } from "../interfaces/auth"



export const getLocalStorageToke = ( ):AuthState =>{
 
  const auth =  localStorage.getItem("tokaut")||""
  return auth!== "" ?JSON.parse(auth): {token:""}
}

export const setLocalStorageAuth = (auth:AuthState):boolean =>{
    try {
        localStorage.setItem("tokaut",JSON.stringify(auth))
        return true
    } catch (error) {
        console.log(error)
        return false
    }
  
} 

export const getCarritoLocalStorage = ():CarritoState =>{
    const carrito =  localStorage.getItem("carshoplstok")||""
  return carrito!== "" ?JSON.parse(carrito): {productos:[]}
} 

export const setCarritoLocalStorage = (carrito:CarritoState):boolean => {
    try {
        localStorage.setItem("carshoplstok",JSON.stringify(carrito))
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}