import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { getRemoteAllProductos } from "../data/products";
import { Filtro, ProductosState } from "../interfaces";
import { ProductsReducerTypes } from "../reducers/productsreducer";
import {store} from '../store/store'


export const getProductsAction: ActionCreator<ThunkAction<Promise<Action<ProductosState>>, ProductsReducerTypes, void,any>> = (
    tokenP:string) =>{

        return async (dispatch: Dispatch<ProductsReducerTypes>): Promise<Action> => {
            try {
              dispatch({type:"[PRODUCTOS] Loading"})
              const products = await getRemoteAllProductos(
                tokenP,
                1,
                21
              );
              return dispatch({ type:"[PRODUCTOS] Get", payload: {products:products.productos,pages:Math.ceil(products.results/20) } });
              
            } catch (error: any) {
             return dispatch({
                type: "[PRODUCTOS] Error",
                payload: { message: error.response.data },
              });
            }
          };
};
export const filterProductsAction:ActionCreator<ThunkAction<Promise<Action<ProductosState>>, ProductsReducerTypes, void,any>> = (
    filtros:Filtro) =>{
      return async (dispatch: Dispatch<ProductsReducerTypes>): Promise<Action> => {
        const {auth} = store.getState()

        const {familias,busqueda} = filtros
        dispatch({type:"[PRODUCTOS] Loading"})
        try {
          const products = await getRemoteAllProductos(
            auth.token,
            1,
            20,
            familias,
            busqueda
          );
           return dispatch({ type:"[PRODUCTOS] Filter", payload: {products:products.productos,pages:Math.ceil(products.results/20),filtros } });
          
        } catch (error: any) {
         return dispatch({
            type: "[PRODUCTOS] Error",
            payload: { message: error.response.data },
          });
        }
      };
};    
export const changePageProductsAction:ActionCreator<ThunkAction<Promise<Action<ProductosState>>, ProductsReducerTypes, void,any>> = (
      newPage:number,
      tokenP:string) =>{
        return async (dispatch: Dispatch<ProductsReducerTypes>): Promise<Action> => {
          const state =     store.getState()
          const {filtros} = state.products
          const {familias,busqueda} = filtros
          dispatch({type:"[PRODUCTOS] Loading"})
          try {
            const products = await getRemoteAllProductos(
              tokenP,
              newPage,
              20,
              familias,
              busqueda
            );
             return dispatch({ type:"[PRODUCTOS] ChangePage", payload: { page:newPage,products:products.productos,pages:Math.ceil(products.results/20) } });
            
          } catch (error: any) {
           return dispatch({
              type: "[PRODUCTOS] Error",
              payload: { message: error.response.data },
            });
          }
        };
};