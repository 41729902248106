import { useState } from "react";
import { Button, Paper, Typography ,Box} from "@mui/material";
import {ColumnDirective,ColumnsDirective,GridComponent,Page,Inject,Sort,} from "@syncfusion/ej2-react-grids";
import { useMisPedidos } from "../../hooks/pedidos/useMisPedidos";
import { ModalPedidoInfo } from "../../components/mispedidos/ModalPedidoInfo";
import {  UltimosPedidos } from "../../interfaces";
import { ModalCorreo } from "../../components/mispedidos/ModalCorreo";


export const MisPedidos = () => {
  const [isOpen, setisOpen] = useState<boolean>(false)
  const [isOpenCorreo, setisOpenCorreo] = useState(false)
  const [selectedPedido, setSelectedPedido] = useState<UltimosPedidos|null>(null) 
  const { pedidos,enviarCorreo } = useMisPedidos();
  const onClose = () =>setisOpen(false)
  const onCloseModalCorreo =() => setisOpenCorreo(false)
  const dispatchModal = (pedido:UltimosPedidos)=>{
    setSelectedPedido(pedido)
    setisOpen(true)
  }
  const dispatchModalCorreo= (pedido:UltimosPedidos)=>{
    setSelectedPedido(pedido)
    setisOpenCorreo(true)
  }
  return (
    <>
      {selectedPedido&& <ModalPedidoInfo isOpen={isOpen} onClose={onClose} pedido={selectedPedido}/>}
      {selectedPedido&& <ModalCorreo isOpen={isOpenCorreo} onClose={onCloseModalCorreo} pedido={selectedPedido}  enviarCorreo={enviarCorreo}/>}

      <Paper elevation={4} sx={{maxWidth:1600,margin:"0 auto",borderRadius:6}}>
        <Box padding={4}>
          <Box mb={3}>
            <Typography variant="h6" align="center">
              Mis ultimos pedidos
            </Typography>
          </Box>
          <GridComponent dataSource={pedidos} allowSorting allowPaging pageSettings={{ pageSize: 10 }} >
             <ColumnsDirective>
                <ColumnDirective width="120" textAlign="Center" autoFit field="id" headerText="Numero pedido" />
                <ColumnDirective width="120" textAlign="Center" autoFit headerText="Numero de documento" template={(f: UltimosPedidos) => <TemplateDoc {...f} />}/>
                <ColumnDirective width="120" textAlign="Center" autoFit headerText="Total de articulos " template={(f:UltimosPedidos)=><p>{f.productos.length}</p>} />
                <ColumnDirective width="120" textAlign="Center" autoFit field="fecha"  type="datetime" headerText="Fecha" format="dd/MM/yyyy hh:mm a"/>
                <ColumnDirective width="120" textAlign="Center" autoFit field="total" headerText="Total" format="c2"/>
                <ColumnDirective width="120" textAlign="Center" autoFit headerText="" 
                template={(pedido: UltimosPedidos) => <TemplateInfo pedido={pedido} onClick={dispatchModal } onSenEmail={dispatchModalCorreo}/>}/> 
             </ColumnsDirective>
             <Inject services={[Sort, Page]} />
          </GridComponent>
        </Box>
      </Paper>
    </>
  );
};



interface PropsTemplateInfo
{
    pedido:UltimosPedidos;
    onClick:(pedido:UltimosPedidos)=>void;
    onSenEmail:(pedido:UltimosPedidos)=>void;
}

const TemplateInfo = ({pedido,onClick,onSenEmail}: PropsTemplateInfo) => {
  
  return (
    <Box sx={{display:"flex"}}>
      <Button size="small" variant="text" onClick={()=>{onSenEmail(pedido)}}>
        enviar correo
      </Button>
      <Button size="small" variant="text" onClick={()=>{onClick(pedido)}}>
        ver
      </Button> 
    </Box>
   
  );
};

const TemplateDoc = ({docEntry}: UltimosPedidos) => {
  return (
    <Typography variant="body2">
      {docEntry!==0?docEntry:"Sin asignar"}
    </Typography>
  );
};
