import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { AppRouter } from "./router/AppRouter";
import { store } from "./store/store";

export const App = () => {
  return (
    <SnackbarProvider maxSnack={3} autoHideDuration={4 * 1000} >
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </SnackbarProvider>
  );
};
