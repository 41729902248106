
import { getCarritoLocalStorage } from "../helpers/localStorage";
import { CarritoState } from "../interfaces";


export type CarritoReducerTypes = 
|{type:"[CARRITO] Change",payload:{carrito:CarritoState}}
|{type:"[CARRITO] DeleteItem",payload:{carrito:CarritoState}}

|{type:"[CARRITO] Clear"}



export const carritoReducer = (state:CarritoState = getCarritoLocalStorage(),action:CarritoReducerTypes):CarritoState => {

    switch (action.type) {
        case "[CARRITO] Change":
            return action.payload.carrito
        case "[CARRITO] Clear":  
            return {productos:[]}
        case "[CARRITO] DeleteItem":
            return action.payload.carrito
        default:
            return state
    }
}