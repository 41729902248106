
import { LockOutlined, SupervisorAccountOutlined } from "@mui/icons-material";
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText,} from "@mui/material";
import { Link } from "react-router-dom";
import { setLocalStorageAuth } from "../../helpers/localStorage";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AuthReducerTypes } from "../../reducers/authreducer";
import { useDispatch } from "react-redux";
import { logOutAction } from "../../actions/auth";

interface Props {
  open: boolean;
  handleClose:()=>void;
}

export const MenuUser = ({ open,handleClose }: Props) => {
  type AppDispatch = ThunkDispatch<AuthReducerTypes, any, AnyAction>;
  const dispatch: AppDispatch = useDispatch();
  const logOut = () => {
    setLocalStorageAuth({
      token: "",
      isLoged: false,
      haveToChange: false,
      error: "",
    });
    dispatch(logOutAction)
    window.location.href="https://www.pegaduro.com.mx"
  };
  return (
    <List>
      <ListItem  disablePadding sx={{ display: "block" }} onClick={handleClose} >
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
          to="mi-informacion"
          component={Link}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >

              <SupervisorAccountOutlined/>
          </ListItemIcon>
          <ListItemText
            primary={"Mi perfil"}
            sx={{ opacity: open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}

          onClick={()=>logOut()}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 3 : "auto",
              justifyContent: "center",
            }}
          >
        <LockOutlined/>
          </ListItemIcon>
          <ListItemText
            primary={"Cerrar Sesión"}
            sx={{ opacity: open ? 1 : 0 }}
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
