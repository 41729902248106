import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Page,
  Inject,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { Box, Button, Paper, Typography } from "@mui/material";
import { useNotaCredito } from "../../hooks";
import { DownloadOutlined } from "@mui/icons-material";
import { NotaCredito } from "../../interfaces";
import { useDownloadFile } from "../../hooks";

export const NotasCredito = () => {
  const { notasCredito } = useNotaCredito();

  return (
    <>
       <Paper elevation={4} sx={{maxWidth:1600,margin:"0 auto",borderRadius:6}}>
        <Box padding={4}>
          <Box mb={3}>
            <Typography variant="h6" align="center">
              Mis Notas de crédito
            </Typography>
          </Box>
          <Box>
            <GridComponent dataSource={notasCredito} allowSorting allowPaging pageSettings={{ pageSize: 10,pageSizes:true }} enableStickyHeader>
              <ColumnsDirective>
                <ColumnDirective width={150} field="numeroDocumento" headerText="Número Documento"/>
                <ColumnDirective width={150} field="serie" headerText="Serie" />
                <ColumnDirective width={150} field="comentarios" headerText="Comentarios" autoFit/>
                <ColumnDirective width={150} field="fecha" type="datetime" headerText="Fecha" format="dd/MM/yyyy hh:mm a"/>
                <ColumnDirective width={150} field="total" headerText="Total" format="c2" autoFit/>
                <ColumnDirective width={150} headerText="" template={(f: NotaCredito) => <TrustTemplate {...f} />}
                />
              </ColumnsDirective>
              <Inject services={[Sort, Page]} />
            </GridComponent>
          </Box>
        </Box>
      </Paper>
    </>
  );
};
const TrustTemplate = (props: NotaCredito) => {
  const { downloadBase64 } = useDownloadFile();
  return (
    <Button
      size="small"
      color="primary"
      variant="text"
      onClick={() => downloadBase64(props.numeroDocumento, 14,props.serie)}
      startIcon={<DownloadOutlined />}
    >
      Descargar
    </Button>
  );
};
