
import qs from "qs"
import { httpClient } from "../config/axiosconfig"
import { ProductsResponse, Promocion } from "../interfaces/products"

export const getRemoteFamilias = async (token:string):Promise<string[]> => {
   
    const response = await httpClient.get<string[]>("Products/get-familias")

    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}

export const getRemoteAllProductos = async(token:string,page:number,pageSize:number,familias:string[]=[],busqueda:string =""):Promise<ProductsResponse> =>{
   
    const response = await httpClient.get<ProductsResponse>(`Products`,{

        headers:{"Authorization":token},
        params:{
            pageSize,
            page,
            busqueda,
            familia:familias
        },
        paramsSerializer:(params)=>(qs.stringify(params))
    })
console.log("--------------DATA-----------");
console.log(response.data);
console.log("---------------FIN DATA----------");
    if(response.status === 200){
        return response.data
    }else{
        throw response.data
    }
}


export const getRemotePromociones = async(token:string):Promise<Promocion[]> =>{
   
    const response = await httpClient.get<Promocion[]>("Products/promociones",{headers:{"Authorization":token}})
  
    console.log(response.data)
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}
