import * as React from "react";
import {Card,CardContent,Box,Typography,Divider,Chip,Stack,Button} from "@mui/material";
import { AddShoppingCartOutlined } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import currencyFormatter from "currency-formatter";
import { Producto } from "../../interfaces";
import { ModalProduct } from "./ModalProduct";
import Logo from "../../assets/img/logoPegaduro.png";

interface Props {
  product: Producto;
}
export const ProductCard = ({ product }: Props) => {
  const { urL_Publica, urlImagen, itemName, descCorta, precio, iva, grupo, familia, } = product;
  const url = urL_Publica && urlImagen ? urL_Publica + urlImagen : Logo;
  const [open, setopen] = React.useState<boolean>(false);
  const handleClose = ()=> setopen(false)
  console.log(product)
  return (
    <>
      <ModalProduct product={product} open={open} onClose={handleClose} />
      <Card sx={{ maxWidth: "100%" ,height:460}}>
    
          <CardContent sx={{display:"flex",flexDirection:"column",height:"100%"}} >
            <Typography align="left" variant="caption">
              {product.itemCode}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img style={{ height: 120 }}src={ url !=="https://www.pegaduro.com.mx/PRODUCTOS/NOT_FOUND_IMAGE.jpg" ? url: Logo } alt={product.itemName} />
            </Box>

            <Typography  align="center" variant="subtitle2" sx={{ marginTop: 3 }}>
              {itemName}
            </Typography>
            <Typography variant="body2" fontWeight="bold" color={green[900]}>
              {currencyFormatter.format( (precio.precio * iva) + precio.precio , { code: "USD" })}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {descCorta || "Descripción no encontrada"}
            </Typography>
            <Divider sx={{ marginY: 4 }} />
            <Stack direction="row" spacing={1}>
              <Chip label={grupo} />
              <Chip label={familia} />
            </Stack>
            <Button sx={{marginTop:"auto"}}  onClick={() => setopen(true)} fullWidth variant="contained"startIcon={<AddShoppingCartOutlined />}>
              Agregar
            </Button>
           
          </CardContent>
       
      </Card>
    </>
  );
};
