import {
  ContentPasteOutlined,
  ProductionQuantityLimitsOutlined,
  ScheduleSendOutlined,
} from "@mui/icons-material";
import { Box, Typography, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { AddressCard } from "../../components/carrito/AddressCard";
import { ProductListCar } from "../../components/carrito/ProductListCar";
import { usePedido } from "../../hooks/pedidos/usePedido";
import { Container } from "@mui/system";

export const Carrito = () => {
  const {handleForm,productos,direcciones,statusPedido,sendPedido,form,promociones,} = usePedido();
  const { comentarios, direccion } = form;

  return (
    <>
      {productos.length > 0 ? (
        <Container>
          <Typography variant="h6" align="center">
            Mi carrito de compras
          </Typography>
          <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={7}>
              <AddressCard
                comentario={comentarios}
                direccion={direccion}
                direcciones={direcciones}
                handleForm={handleForm}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <ProductListCar promociones={promociones} />
            </Grid>

          </Grid>
          <Box sx={{ mt: 2 }}></Box>

          <Box>
            <LoadingButton
              variant="contained"
              fullWidth
              loading={statusPedido.loading}
              loadingPosition="start"
              loadingIndicator={<ScheduleSendOutlined />}
              color="primary"
              startIcon={<ContentPasteOutlined />}
              sx={{ marginTop: 5 }}
              onClick={sendPedido}
            >
              Crear Orden de Venta
            </LoadingButton>
          </Box>
        </Container>
      ) : (
        <CarritoVacio />
      )}
    </>
  );
};

export const CarritoVacio = () => {
  return (
    <Container sx={{display: "flex",justifyContent: "center",alignItems: "center",height: "80vh",}}>
      <Box>
        <Box sx={{display: "flex",justifyContent: "center",alignItems: "center",marginBottom: 5,}}>
          <ProductionQuantityLimitsOutlined color="primary" sx={{ fontSize: 200 }}/>
        </Box>
        <Typography align="center" variant="h5">
          Aun no tienes Productos en el carrito
        </Typography>
      </Box>
    </Container>
  );
};
