
import { httpClient } from "../config/axiosconfig"
import { CustomerInfo, Direccion ,MesVendido} from "../interfaces"


export const getRemoteCustomerInfo = async (token:string):Promise<CustomerInfo> => {

    const response = await httpClient.get<CustomerInfo>("Customer",{headers:{"Authorization":token}})

    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}

export const getRemoteDirecciones = async (token:string):Promise<Direccion[]> => {
    const response = await httpClient.get<Direccion[]>("Customer/direcciones",{headers:{"Authorization":token}})

    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}


export const getRemoteMesesVendidos = async (token:string,months:number):Promise<MesVendido[]> => {
    const response = await httpClient.get<MesVendido[]>(`Customer/toneladas-vendidas?months=${months}`,{headers:{"Authorization":token}})

    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}

export const insertRemoteComentario = async (token:string,comentario:string):Promise<number> => {
    const response = await httpClient.post<number>(`Customer/insert-comentario`,{comentario},{headers:{"Authorization":token}})
    if(response.status === 200 ){
        return response.data
    }else{
       throw response.data
    }
}