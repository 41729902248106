import { Box ,Typography} from "@mui/material";
import {ProductoUltimosPedido } from "../../interfaces";


interface Props {
    producto: ProductoUltimosPedido;
  }
export const PedidoProduct = ({producto}:Props) => {
  return (
    <Box>
      <Box>
        <Typography variant="subtitle2">{producto.nombreProducto}</Typography>
        <Typography variant="body2">Cantidad: {producto.cantidad}</Typography>
      </Box>
     
    </Box>
  );
};
