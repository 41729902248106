import { useEffect } from "react";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Copyright } from "../../components/login/CopyRigth";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { changePasswordAction } from "../../actions/auth";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { AuthReducerTypes } from "../../reducers/authreducer";

export const ChangePassword = () => {


  const { enqueueSnackbar } = useSnackbar();
  
  type AppDispatch = ThunkDispatch<AuthReducerTypes, any, AnyAction>; 
  const dispatch:AppDispatch = useDispatch();
  const auth =  useSelector<RootState,RootState["auth"]>(state=> state.auth)

  let navigate = useNavigate();

  
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
    
    const currentPassword = data?.get("currentPassword")?.toString();

    const newPassword = data.get("newPassword")?.toString();

    const confirmNewPassword = data.get('confirmNewPassword')?.toString();
    if (currentPassword && newPassword && confirmNewPassword) {
      if(newPassword === confirmNewPassword){
        dispatch( changePasswordAction(currentPassword, newPassword,auth.token));
      }else {
        enqueueSnackbar("Las contraseñas deben coincidir",{variant:"error"})
      }
    
    }
  };

  useEffect(() => {
    if (auth.error) {
      enqueueSnackbar(auth.error, { variant: "error" });
    }else if(!auth.haveToChange){
      navigate("/auth", { replace: false });
    }
  }, [auth,navigate,enqueueSnackbar])
  

  return (
    <>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent:"center"
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Portal de clientes
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="currentPassword"
            label="Contraseña Actual"
            name="currentPassword"
            type="password"
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="newPassword"
            label="Nueva Contraeña"
            name="newPassword"
            type="password"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="confirmNewPassword"
            label="Confirma la Nueva Contraseña"
            type="password"
            id="confirmNewPassword"
            autoComplete="current-password"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Cambiar contraseña
          </Button>

          <Copyright sx={{ mt: 5 }} />
        </Box>
      </Box>
    </>
  );
};

export default ChangePassword;
