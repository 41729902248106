import { Box, Divider, Paper, Typography, Grid } from "@mui/material";
import { useCustomerInfo } from "../../hooks";
import { SaldoCard } from "../../components/customer/SaldoCard";
import { ContactoCard } from "../../components/customer/ContactoCard";
import { CreditoCard } from "../../components/customer/CreditoCard";
import { GraficaToneladasVendidas } from "../../components/customer/GraficaToneladasVendidas";
// import { Banner } from "../../components/customer/Banner";

export const CustomerInfo = () => {


  const {customer,mesesVendidos} = useCustomerInfo() 
  console.log(customer);
  return (
    <>
     <Box  sx={{maxWidth:1800,margin:"0 auto" ,padding:1}}>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} >
            <Paper elevation={2} sx={{borderRadius:10}}>
              <Box sx={{maxWidth:"100%",paddingX:7,paddingTop:2,paddingBottom:3}}>
              <Box >
                <Box sx={{display:"flex",justifyContent:"center",alignContent:"center",marginBottom:5}}>
                  <img src="img/user-custom-info.png" alt="img" style={{maxWidth:"100%",height:180}}/>
                </Box>
                <Grid >
                  <Typography variant="subtitle2" align="center">
                    {customer?.cardName}
                  </Typography>

                  <Box my={2}>
                  <Divider />
                  </Box>
                  <Typography variant="body2" align="center">RFC:{customer?.rfc}</Typography>
                  <Typography variant="body2" align="center">
                    Domicilio: {customer?.direccion}
                  </Typography>
                  <Typography variant="body2" align="center">
                    Vendedor: {customer?.vendedor}
                  </Typography>
                  {customer?.emailsFacturaList&&customer?.emailsFacturaList.length!==0? 
                    <Box sx={{marginTop:2}}>
                      <Typography variant="subtitle2"  >{"Correos facturación"}</Typography>   
                       {customer?.emailsFacturaList.map((c,index)=>
                       <Box key={index} sx={{width:"100%"}}>
                          <Typography  variant="caption" textAlign="center">{c}</Typography>
                       </Box>)}
                    </Box>            
                   :null
                  }
                </Grid>
              </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={8}>
              <Paper elevation={4} sx={{borderRadius:6}}>
                <Box sx={{ padding: 2 }}>
                  <GraficaToneladasVendidas mesesVendidos={mesesVendidos} />
                </Box>  
              </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{marginTop:10}}>
        <Grid container spacing={2}>
           <Grid item xs={12} md={4}>
             <SaldoCard customer={customer}/>
           </Grid>
           <Grid item xs={12} md={4} >
             <ContactoCard customer={customer}/>
           </Grid>
           <Grid item xs={12} md={4}>
             <CreditoCard customer={customer}/>
           </Grid>
        </Grid>
      </Box>
      {/* <Box sx={{ marginY:4,maxHeight:220}}>
        <Banner/>
      </Box> */}
     </Box>
    </>
  );
};
