
import {Paper,Box,Typography,Divider} from "@mui/material";
import { ContactsOutlined} from "@mui/icons-material";
import { CustomerInfo } from '../../interfaces'
interface Props
{
    customer?:CustomerInfo
}
export const ContactoCard = ({customer}:Props) => {
  return (
    <Paper elevation={6} sx={{borderRadius:5}}>
        <Box sx={{ padding: 2,height:220}}>
          <Box >
            <Box >
              <ContactsOutlined sx={{ width: "100%", fontSize: 50 }} color="primary"/>
            </Box>
            <Box>
              <Typography variant="subtitle2" align="center">
                {"Contacto"}
              </Typography>
              <Box mb={2}> 
                <Divider />
              </Box>
              <Typography variant="body2" align="center">Email: {customer?.email}</Typography>
              {customer?.emailPagos&&<Typography variant="body2" align="center">Email pagos: {customer?.emailPagos} </Typography>}
              <Typography variant="body2" align="center">Teléfono: {customer?.telefono}</Typography>
             
            </Box>
          </Box>
        </Box>
  </Paper>
  )
}
