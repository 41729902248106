
import {Paper,Box,Typography,Divider} from "@mui/material";
import { CreditCardOutlined} from "@mui/icons-material";
import currencyFormatter  from 'currency-formatter'
import { CustomerInfo } from '../../interfaces'
import {green,red} from '@mui/material/colors'
interface Props
{
    customer?:CustomerInfo
}
export const CreditoCard = ({customer}:Props) => {
  return (
  <Paper elevation={4} sx={{borderRadius:4}}>
    <Box sx={{ padding: 2 ,height:220}}>
      <Box>
        <Box>
          <CreditCardOutlined sx={{ width: "100%", fontSize: 50 }} color="primary"/>
        </Box>
        <Box>
          <Typography variant="subtitle2" align="center">
            {"Crédito"}
          </Typography>
          <Box mb={2}>
            <Divider />
          </Box>
          <Typography variant="body2" align="center">Límite de crédito: {customer&&currencyFormatter.format(customer.credito,{code:"USD"})  }</Typography>
          <Typography variant="body2" align="center">
            Condiciones de pago: {customer?.condicionesPago}
          </Typography>
          <Typography variant="subtitle2" align="center" color={customer?.diasMorosidad!<=15?green[900]:red[900]} >
            Días de morosidad: {customer?.diasMorosidad}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Paper>
  )
}
